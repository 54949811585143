import React from "react";
import resumePDF from "../../shakiba-Abdulrashid-resume.pdf";
import "./Resume.css";
function Resume() {
  return (
    <div className="resume">
      <embed src={resumePDF} width="500" height="700" type="application/pdf" />
    </div>
  );
}

export default Resume;
