import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin, FaSquareGithub } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center fs-1 text-info">
            <Link
              className="text-info"
              to="https://www.linkedin.com/in/shakiba-abdulrashid/"
              target="_blank"
            >
              <FaLinkedin />
            </Link>
            <Link
              className="text-info"
              to="https://github.com/shakiba333"
              target="_blank"
            >
              <FaSquareGithub />
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
