import React from "react";
import { Col, Row } from "react-bootstrap";
import Project from "../../components/Project/Project";
const projects = [
  {
    _id: "1",
    name: "Infinite Mall",
    image: "https://i.imgur.com/TplcEdn.png",
    description: "eCommerce app",
    stack:
      "MERN Stack built with React-redux, Redux Toolkit, MongoDB, Express.js",
    githubLink: "https://github.com/shakiba333/e-commerce-capstone",
    liveSiteLink: "https://infinitemall.onrender.com/",
  },
  {
    _id: "2",
    name: "Chat App",
    image: "https://i.imgur.com/BWFEyC6.png",
    description:
      "Chat app allows users to log in or sign up and can customize their profiles by adding a photo, editing profile information, and viewing their chat history. Users can start chatting with other registered users.",
    stack: "HTML, CSS, Python, Django, PostgreSQL",
    githubLink: "https://github.com/shakiba333/chatapp/tree/main",
    liveSiteLink: "https://shy-bush-5706.fly.dev/",
  },
  {
    _id: "3",
    name: "Book Hub",
    image: "https://i.imgur.com/MqcAk0f.png",
    description:
      "BookHub is an interactive web application that allows users to explore a collection of books, add reviews, and manage book information.",
    stack: "MongoDB, Express, HTML, and CSS",
    githubLink: "https://github.com/shakiba333/chatapp/tree/main",
    liveSiteLink: "https://black-darkness-2191.fly.dev/books",
  },
  {
    _id: "4",
    name: "Tech Blog",
    image: "https://i.imgur.com/Eg96Ep0.png",
    description:
      "A blog application that allows an admin to perform CRUD operations on blog posts. It uses MongoDB as the database to store blog information.",
    stack: "MERN Stack, MongoDB, Express.js, React.js, Node.js",
    githubLink: "https://github.com/bcarpenter7/react-blog-new",
    liveSiteLink:
      "https://64ca4aa211530b1691cd0642--resonant-rolypoly-eabc9a.netlify.app/",
  },
  {
    _id: "5",
    name: "Solitaire Game",
    image: "https://i.imgur.com/oAYmr6q.png",
    description:
      "Card based game where the user arranges a deck of cards in a specific pattern or order",
    stack: "HTML, CSS, JavaScript",
    githubLink: "https://github.com/shakiba333/Solitaire",
    liveSiteLink: "https://main--golden-sprinkles-454b23.netlify.app/",
  },
];
function Projects() {
  return (
    <>
      <Row>
        {projects.map((project) => (
          <Col key={project._id} sm={12} md={6} lg={4}>
            <Project project={project} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default Projects;
