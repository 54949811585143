import React from "react";
import {
  BiLogoJavascript,
  BiLogoPython,
  BiLogoPostgresql,
  BiLogoReact,
  BiLogoRedux,
  BiLogoGit,
} from "react-icons/bi";
import { SiMongodb, SiDjango, SiPostman } from "react-icons/si";
import { AiFillHtml5 } from "react-icons/ai";
import { DiCss3 } from "react-icons/di";
import { FaNode, FaBootstrap } from "react-icons/fa";
import Skill from "../../components/Skill/Skill";
import { Col, Container, Row } from "react-bootstrap";
const skills = [
  {
    name: "Languages & Databases",
    stack: [
      { icon: <BiLogoJavascript />, label: "JavaScript" },
      { icon: <BiLogoPython />, label: "Python" },
      { icon: <AiFillHtml5 />, label: "HTML" },
      { icon: <DiCss3 />, label: "CSS" },
      { icon: <SiMongodb />, label: "MongoDB" },
      { icon: <BiLogoPostgresql />, label: "PostgreSQL" },
    ],
  },
  {
    name: "Libraries & Frameworks",
    stack: [
      { icon: <BiLogoReact />, label: "React" },
      { icon: <FaNode />, label: "Express.js" },
      { icon: <SiDjango />, label: "Django" },
      { icon: <FaBootstrap />, label: "Bootstrap" },
      { icon: <BiLogoRedux />, label: "React-redux" },
    ],
  },
  {
    name: "Tools & Platforms",
    stack: [
      { icon: <BiLogoGit />, label: "Git" },
      { icon: <SiPostman />, label: "Postman" },
    ],
  },
];
function Skills() {
  return (
    <Container
      className=" d-flex align-items-center justify-content-center flex-column"
      style={{ minHeight: "100vh" }}
    >
      <Row>
        <Col>
          <h4>
            I love to learn new things and experiment with new technologies.
            These are some of the major languages, technologies, tools and
            platforms I have worked with:
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center">
            {skills.map((skill, index) => (
              <Skill key={index} name={skill.name} stack={skill.stack} />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Skills;
