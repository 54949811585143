import { Navbar, Nav, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Header.css";
function Header() {
  return (
    <header>
      <Navbar
        variant="white"
        expand="lg"
        collapseOnSelect
        style={{ backgroundColor: "rgb(235, 233, 225)" }}
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand className="nav-link-hover text-muted fw-bold">
              Home
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="navbarScroll"></Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav>
              <LinkContainer to="/projects">
                <Nav.Link className="nav-link-hover px-3 text-info fw-bold active">
                  Projects
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              <LinkContainer to="/skills">
                <Nav.Link className="nav-link-hover px-3 text-info fw-bold">
                  Skills
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              {/* <LinkContainer to="/resume">
                <Nav.Link className="nav-link-hover px-3 text-info fw-bold">
                  Resume
                </Nav.Link>
              </LinkContainer> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
