import React from "react";
import { Card } from "react-bootstrap";

function Skill({ name, stack }) {
  return (
    <>
      <Card className="shadow-lg bg-body mb-3 rounded" style={{ border: 0 }}>
        <Card.Body>
          <Card.Title className="fw-bold">{name}</Card.Title>
          <Card.Subtitle className="d-flex align-items-center justify-content-center ">
            {stack.map((stack, stackIndex) => (
              <div className="text-center">
                <span
                  className="fs-1 text-info text-center p-2"
                  key={stackIndex}
                >
                  {stack.icon}
                </span>
                <p className="p-2 text-center">{stack.label}</p>
              </div>
            ))}
          </Card.Subtitle>
        </Card.Body>
      </Card>
    </>
  );
}

export default Skill;
