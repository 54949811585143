import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FaSquareGithub } from "react-icons/fa6";
import { RiExternalLinkLine } from "react-icons/ri";
function Project({ project }) {
  return (
    <>
      <Card
        className="shadow-lg bg-body rounded"
        style={{ border: "0", marginTop: "20px" }}
      >
        <Card.Img className="rounded" src={project.image} variant="top" />
        <Card.Body>
          <Card.Title as="div" className="text-center">
            <strong>{project.name}</strong>
          </Card.Title>
          <Card.Text as="p" className="text-center text-muted">
            {project.description}
          </Card.Text>
          <Card.Text as="h6" className="text-center">
            {project.stack}
          </Card.Text>
          <Row>
            <Col className="text-center">
              <Card.Link className="text-info fs-2" href={project.githubLink}>
                <FaSquareGithub />
              </Card.Link>
              <Card.Link className="text-info fs-2" href={project.liveSiteLink}>
                <RiExternalLinkLine />
              </Card.Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default Project;
