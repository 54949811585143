import { Row, Col, Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import { FaArrowRight } from "react-icons/fa6";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
function Home() {
  return (
    <>
      <Container
        className=" d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <Row>
          <Col>
            {" "}
            <h1 className="text-muted text-center">
              Hello, I'm{" "}
              <span className="font-monospace text-info">
                Shakiba Abdulrashid
              </span>
              .
            </h1>
            <h1 className="text-muted text-center">
              I'm a full-stack web developer.
            </h1>
          </Col>
          <Row>
            <Col className="text-center">
              <LinkContainer to="/projects">
                <Link>
                  {" "}
                  <button
                    type="button"
                    className="btn btn-outline-info"
                    style={{ width: "20%" }}
                  >
                    View my work <FaArrowRight />
                  </button>
                </Link>
              </LinkContainer>
            </Col>
          </Row>
          <Row>
            <Col>
              <Footer />
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default Home;
