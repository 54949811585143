import Header from "./components/Header/Header";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import './App.css';

function App() {
  return (
    <>
      <Header />
      <main>
        <Container>
          <Outlet />
        </Container>
      </main>

    </>
  );
}

export default App;
